@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Protest+Strike&family=Seymour+One&family=Young+Serif&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Creepster&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ceviche+One&family=Creepster&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ceviche+One&family=Creepster&family=Flavors&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Metal+Mania&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Risque&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Piedra&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Joti+One&display=swap");

.App {
  text-align: center;
  font-family: "Roboto Flex", sans-serif;
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: "Inconsolata", monospace;
  /* font-family: "Creepster", system-ui; */
  /* font-family: "Protest Strike", sans-serif; */
  /* font-family: "Josefin Sans", cursive; */
  /* font-family: 'Josefin Sans', sans-serif; */
  color: var(--text-color);
  background-color: var(--background-color);
}

.modal {
  /* font-family: "Inconsolata", monospace !important; */
  font-family: "Risque", sans-serif !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* font-family: "Seymour One", sans-serif; */
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.inputWithButton {
  position: relative;
  height: 100px;
  width: 200px;
}

.inputWithButton input {
  width: 70%;
  height: 25px;
  padding-right: 60px;
}

.inputWithButton button {
  position: absolute;
  right: 0;
  top: 5px;
}

/* MyComponent.css */
.resize-button img {
  width: 45px;
}

@media (max-width: 768px) {
  .resize-button img {
    width: 35px; /* Adjust the size as needed */
  }
}

@media (max-width: 576px) {
  .resize-button img {
    width: 30px; /* Adjust the size as needed */
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in {
  animation: slideIn 0.5s ease-out;
}

/* Hide the download button */
audio::-webkit-media-controls-enclosure {
  overflow: hidden;
}

audio::-webkit-media-controls-panel {
  width: calc(
    100% + 40px
  ); /* Adjust the width to ensure controls are centered */
}

audio::-webkit-media-controls-button {
  display: none;
}

audio::-webkit-media-controls-volume-slider {
  display: none;
}

audio::-webkit-media-controls-current-time-display {
  display: block;
  width: auto;
}

audio::-webkit-media-controls-time-remaining-display {
  display: block;
  width: auto;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Allows the buttons to wrap on smaller screens */
  gap: 10px;
}

.action-button {
  margin: 5px;
  border-radius: 50px;
  width: 150px;
  height: 55px;
}

@media (max-width: 768px) {
  .button-container {
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
  }

  .action-button {
    width: 100%;
  }
}

/* Add this to your CSS file */
.gradient-background {
  background: linear-gradient(to right, yellow, red);
}

/* Styling the wheel */
#wheel {
  width: 3cm;
  height: 3cm;
  border: 5px solid gold; /* This creates the outer circle (wheel) */
  background-color: #00d0c9; /* Inside of the circle in gold */
  border-radius: 50%; /* Makes the div a circle */
  position: relative;
  margin: 20px auto;
  animation: spin infinite linear; /* Infinite rotation animation */
  transform-origin: center;
}

/* Line in the center */
#wheel::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 2px;
  height: 100%;
  background-color: white;
  transform: translateX(-50%);
}

/* Small circle in the middle */
/* #wheel::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.11cm; 
  height: 0.11cm;
  background-color: white; 
  border-radius: 50%; 
  transform: translate(-50%, -50%); 
} */

/* Rotation animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
